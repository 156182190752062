html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /*overflow: auto;*/
}

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
  segoe ui, arial, sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#root {
  /*overflow: hidden;*/
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  /*background: #FFFFFF;*/

  /*overflow: auto;*/
  /*overflow-y: auto;*/
}

a,
a:visited {
  color: rgb(31, 182, 255);
  cursor: pointer;
}

a:active {
  color: rgb(67, 191, 253);
  cursor: pointer;
}

.app-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -180px;
  z-index: 9999;

  /*opacity:0;*/
  transform:translateX(0);
  transition:.4s;

  background: #9D81EE;

  height: 100%;
  width: 180px;
  display: none;

  /*z-index: 100000;*/
}

.app-menu-container-show {
  /*opacity:1;*/
  transform:translateX(-180px);
  transition:.4s;
  display: block;
}

.app-menu-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
